<template>
  <div class="adminUser container">

    <!-- ------------------------------- Modal: User Details  ------------------------------------------ -->

	<CModal alignment="center" title="User Details" color="info" :show.sync="modalUser" size="lg">	
		<div slot="header">
			<h5 v-if="existingUser">User: {{user.name}}</h5>
			<h5 v-else>New User</h5>
		</div>	  

		<div slot="footer">
			<CButton color="dark" class="mr-3" v-on:click.prevent="serverCall('addUpdateUser')">Save</CButton>
			<CButton color="dark" class="mr-3" v-on:click.prevent="modalUser=false">Close</CButton>
			<p class="text-danger" align="left">{{alertUser}}</p>
			<div>
				<label for="loadingMsgUser" v-if="loadingUser">{{loadingMsgUser}}</label>
				<scale-loader :loading="loadingUser"></scale-loader>
			</div>
		</div>	

		<form>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Name</label>
				<input type="text" class="col-sm-8 form-control" v-model="user.name">
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Email</label>
				<input :disabled=existingUser type="text" class="col-sm-8 form-control" v-model="user.email">
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Active?</label>
				<div class="col-sm-3 text-left">
					<select class="form-control" v-model="user.activeFlag">
						<option value=true>Yes</option>
						<option value=false>No</option>
					</select>
				</div>

			</div>
		</form>

	</CModal>

    <!-- ------------------------------- Modal: Module Permissions ------------------------------------------ -->

	<CModal alignment="center" title="Module Permissions" color="info" :show.sync="modalPermissions" size="lg">	
		<div slot="header">
			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Module </label>
				<label class="col-sm-1 col-form-label font-weight-bold text-right"></label>
				<div class="col-sm-8">
					<select class="form-control" v-model="currentMod">
						<option v-for="mod in orgMods" :value="mod">
							{{ mod }}
						</option>
					</select>
				</div>
			</div>
		</div>	  

		<div slot="footer">
			<CButton color="dark" class="mr-3" v-on:click.prevent="serverCall('addUpdateUser')">Save</CButton>
			<CButton color="dark" class="mr-3" v-on:click.prevent="modalPermissions=false">Close</CButton>
			<p class="text-danger" align="left">{{alertUser}}</p>
			<div>
				<label for="loadingMsgUser" v-if="loadingUser">{{loadingMsgUser}}</label>
				<scale-loader :loading="loadingUser"></scale-loader>
			</div>
		</div>	

		<form>
			<div v-if="currentMod=='Inventory Ledger'">
				<div class="form-group row">
					<label class="col-sm-2 col-form-label font-weight-bold text-right">Enabled</label>
					<input type="checkbox" id="checkbox" v-model="user.mod_Inventory.enabled">
				</div>

				<div v-if="user.mod_Inventory.enabled==true">
					<div class="form-group row">
						<label class="col-sm-2 col-form-label font-weight-bold text-right">Roles</label>
						<multiselect v-model="user.mod_Inventory.roles" class="col-sm-8" 
									:options="orgRoles" 
									:multiple="true"> 
						</multiselect>
					</div>

					<div class="form-group row">
						<label class="col-sm-2 col-form-label font-weight-bold text-right">Dashboards</label>
						<multiselect v-model="user.mod_Inventory.dashboards" class="col-sm-8" 
									:options="orgDashboards" 
									:multiple="true"> 
						</multiselect>
					</div>

					<div class="form-group row">
						<label class="col-sm-2 col-form-label font-weight-bold text-right">Locations</label>
						<multiselect v-model="user.mod_Inventory.locations" class="col-sm-8" 
									:options="orgLocations" 
									:multiple="true"> 
						</multiselect>
					</div>

					<div class="form-group row">
						<label class="col-sm-2 col-form-label font-weight-bold text-right">Items</label>
						<multiselect v-model="user.mod_Inventory.items" class="col-sm-8" 
									:options="orgItem1s" 
									:multiple="true"> 
						</multiselect>
					</div>
				</div>
			</div>
			<div v-else-if="currentMod=='Sale Ledger'">
				<div class="form-group row">
					<label class="col-sm-2 col-form-label font-weight-bold text-right">Enabled</label>
					<input type="checkbox" id="checkbox" v-model="user.mod_SaleOrder.enabled">
				</div>

				<div v-if="user.mod_SaleOrder.enabled==true">
					<div class="form-group row">
						<label class="col-sm-2 col-form-label font-weight-bold text-right">Roles</label>
						<multiselect v-model="user.mod_SaleOrder.roles" class="col-sm-8" 
									:options="orgRoles" 
									:multiple="true"> 
						</multiselect>
					</div>

					<div class="form-group row">
						<label class="col-sm-2 col-form-label font-weight-bold text-right">Locations</label>
						<multiselect v-model="user.mod_SaleOrder.locations" class="col-sm-8" 
									:options="orgLocations" 
									:multiple="true"> 
						</multiselect>
					</div>
				</div>
			</div>
			<div v-else-if="currentMod=='Purchase Ledger'">
				<div class="form-group row">
					<label class="col-sm-2 col-form-label font-weight-bold text-right">Enabled</label>
					<input type="checkbox" id="checkbox" v-model="user.mod_PurchaseOrder.enabled">
				</div>

				<div v-if="user.mod_PurchaseOrder.enabled==true">
					<div class="form-group row">
						<label class="col-sm-2 col-form-label font-weight-bold text-right">Roles</label>
						<multiselect v-model="user.mod_PurchaseOrder.roles" class="col-sm-8" 
									:options="orgRoles" 
									:multiple="true"> 
						</multiselect>
					</div>

					<div class="form-group row">
						<label class="col-sm-2 col-form-label font-weight-bold text-right">Locations</label>
						<multiselect v-model="user.mod_PurchaseOrder.locations" class="col-sm-8" 
									:options="orgLocations" 
									:multiple="true"> 
						</multiselect>
					</div>
				</div>
			</div>
			<div v-else-if="currentMod=='System Administration'">
				<div class="form-group row">
					<label class="col-sm-2 col-form-label font-weight-bold text-right">Enabled</label>
					<input type="checkbox" id="checkbox" v-model="user.mod_SystemAdmin.enabled">
				</div>

				<div v-if="user.mod_SystemAdmin.enabled==true">
					<div class="form-group row">
						<label class="col-sm-2 col-form-label font-weight-bold text-right">Roles</label>
						<multiselect v-model="user.mod_SystemAdmin.roles" class="col-sm-8" 
									:options="orgRoles" 
									:multiple="true"> 
						</multiselect>
					</div>
				</div>
			</div>

		</form>

	</CModal>

    <!-- ---------------------------------------  Main  -------------------------------------------------- -->

	<p class="font-weight-bold" align="center">Admin Users</p>

	<div>
		<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
		<scale-loader :loading="loading"></scale-loader>
	</div>
	<p class="text-danger" align="left">{{alert}}</p>

	<br>

   	<!-- ---------------------------------------  AdminUser Maint -------------------------------------------------- -->

	<vue-good-table ref="userTable" 
							:columns="colsUsers" 
							:rows="rowsUsers" 
							@on-row-click="rowChangedUser" 
							:selectOptions="{
									enabled: true,
									selectOnCheckboxOnly: false, 
							}">
			<div slot="table-actions">
				<CButton color="dark" class="mr-4" v-on:click.prevent="openNewUser()">New</CButton>
			</div>
			<div slot="selected-row-actions">
				<CButton color="dark" v-on:click.prevent="openExistingUser()">Details</CButton>
				<CButton color="dark" class="ml-2" v-on:click.prevent="openPermissions()">Module Permissions</CButton>
				<CButton color="dark" class="ml-2 mr-2" v-on:click.prevent="serverCall('deleteUser')">Delete</CButton>
			</div>
	</vue-good-table>

  </div>
</template>

<script>
import axios from "axios"
import Datepicker from 'vuejs-datepicker'
import ScaleLoader from "@/views/utils/ScaleLoader"
import { firebaseAuth } from '../../firebaseConfig.js';
import Helper from "../utils/Helper.vue"
import Multiselect from 'vue-multiselect'

export default {
	name: 'location',

	mixins: [
		Helper
	],

    components: {
		Datepicker,
        ScaleLoader,
		Multiselect,
	},
		
	data () {
		return {
			orgId: null,
			orgRoles: ['Entry', 'Reports', 'Super'],
			orgDashboards: ['1', '2'],
			orgLocations: [],
			orgItem1s: [],
			orgMods: [],

			alert: '',
            loading: false,
            loadingMsg: null,

			currentModule: 'inventory',  // inventory, saleOrder, purchaseOrder, sysAdmin

			// ************* AdminUsers ************
			// email, name, activeFlag, betaUser, mods, mod_Inventory, mod_SaleOrder, mod_PurchaseOrder, mod_SystemAdmin
			
			alertUser: '',
            loadingUser: false,
            loadingMsgUser: null,

			modalUser: false,
			user: {},
			curRowUser: null,
			existingUser: false,

			rowsUsers: [],
			colsUsers: [
					{
							label: 'Name',
							field: 'name',
					},
					{
							label: 'Email',
							field: 'email',
					},
					{
							label: 'Active?',
							field: 'activeFlag',
    						formatFn: this.formatFn,
					},
					{
							label: 'Inventory',
							field: 'mod_Inventory.enabled',
    						formatFn: this.formatFn,
					},
					{
							label: 'Sale Order',
							field: 'mod_SaleOrder.enabled',
							formatFn: this.formatFn
					},
					{
							label: 'Purchase Order',
							field: 'mod_PurchaseOrder.enabled',
							formatFn: this.formatFn
					},
					{
							label: 'System Admin',
							field: 'mod_SystemAdmin.enabled',
							formatFn: this.formatFn
					},
			],

			// ************* Permissions ************

			// alertPermissions: '',
            // loadingPermissions: false,
            // loadingMsgPermissions: null,

			modalPermissions: false,
			currentMod: '',

			permission: {},

			// *************************

            pagination_options: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [10, 20, 30, 40],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
			},
		}
	},

	computed: {
	},

	watch: {
	},

	mounted(){
		this.orgId = this.$route.params['orgId']
		// todate = current date
		this.todate = new Date()
		this.serverCall('getAdminUsers')
	},


	methods: {
		formatFn(value) {
			if (value==true){
				return ('Enabled')
			}
			else
				return ('Disabled')
		},

        serverCall(name) {
            var self = this

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getAdminUsers":
								self.getAdminUsers(idToken)
								break;

							case "addUpdateUser":
								self.dbColAlert = ""

								if (!self.user.name) {
									self.alertUser = 'User name not specified.'
									return
								}
								if (!self.user.email) {
									self.alertUser = 'User email not specified.'
									return
								}

								var rowsTemp = self.rowsUsers.slice()
								// Prepare new user list
								if (self.existingUser){
									rowsTemp[self.curRowUser.originalIndex] = self.user
								}
								else{
									rowsTemp.push(self.user)
								}

								// Check if any user email is duplicate
								var userEmails = []
								for (const user of rowsTemp){
									if (userEmails.includes(user['email'])){
										self.alertUser= 'Duplicate user email values not allowed'
										return
									}
									else {
										userEmails.push(user['email'])
									}
								}

								// Update rowsDBcols
								self.rowsUsers = rowsTemp

								if (self.existingUser)
									self.addUpdateUser('Update', self.user, idToken)
								else
									self.addUpdateUser('Add', self.user, idToken)

								break

							case "deleteUser":
								// Check if user is selected
								if (self.curRowUser==null){
									self.alert = "User not selected."
									return
								}
								this.$confirm(self.user.name + " - will be deleted. Are you sure?").then(() => {
									var userIndex = self.curRowUser['originalIndex']
									self.rowsUsers.splice(userIndex, 1)
									self.addUpdateUser('Delete', self.user, idToken)
								})
								break;

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Please login first."
				}
			})
		},

		// ************* Admin Users ************

        getAdminUsers(authToken) {
            var self = this
			self.alert = null

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Admin Users..."

            var formData = new FormData()

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetAdminUsers/',
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'multipart/form-data',
					},
					data: formData,
				})
				.then(function (response) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					if (response.data.err != ''){
						self.alert = response.data.err
					}
					else {
						self.rowsUsers = response.data.users
						self.orgLocations = response.data.orgLocations
						self.orgItem1s = response.data.orgItem1s
						self.orgMods = response.data.orgMods
					}
				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					self.alert = String(error)
				})
		},


		// Save user changes - for new as well as existing users
        addUpdateUser(opType, localUser, authToken) {
            var self = this
            this.alertUser = ""

			// Set loading message
			self.loadingUser = true
			self.loadingMsgUser = "Adding/Updating User..."

			var formData = new FormData()
			formData.append('user', JSON.stringify(localUser))
			formData.append('opType', opType)

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/AddUpdateAdminUser/',
					data: formData,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(function (response) {
					if (response.data.err != '') {
						self.alertUser = response.data.err
					}
					else {
						self.modalUser = false
						self.modalPermissions = false
					}
					// Reset loading message
					self.loadingUser = false
					self.loadingMsgUser = null

				})
				.catch(function (error) {
					// Reset loading message
					self.loadingUser = false
					self.loadingMsgUser = null
					self.alertUser = String(error)
				})

		},

		// ******************** Admin Users ***************

		rowChangedUser(params){	
			// Current selected user
			if (this.curRowUser != null){
				// If new row is not same as previous row, uncheck previous row
				if (this.curRowUser.originalIndex!=params.row.originalIndex)
					this.curRowUser.vgtSelected = false
			}
			// New selected row
			this.curRowUser = params.row
			this.user= this.curRowUser
		},

		// Open details for existing user
		openExistingUser(){
			this.alertUser = null

            // Check if user is selected
			if (this.curRowUser==null){
				this.alert = "User not selected."
				return
			}
			this.user= this.curRowUser
			this.existingUser = true
			this.modalUser = true
		},

		// Open details for new User
		openNewUser(){
			this.alertUser = null

			// Initialize user object
			this.user = {
				name: '',
				email: '',
				activeFlag: true,
				betaTest: false,
				mods: [],
				mod_Inventory: {'enabled': false, 'roles': [], 'locations': [], 'items':[], 'dashboards': []},
				mod_SaleOrder: {'enabled': false, 'roles': [], 'locations': []},
				mod_PurchaseOrder: {'enabled': false, 'roles': [], 'locations': []},
				mod_SystemAdmin: {'enabled': false, 'roles': []},
				vgtSelected: false
			}

			this.existingUser = false
			this.modalUser = true
		},

		openPermissions(){
			this.alertUser = null

            // Check if user is selected
			if (this.curRowUser==null){
				this.alert = "User not selected."
				return
			}
			this.user= this.curRowUser
			if (this.orgMods.length > 0)
				this.currentMod = this.orgMods[0]
			else{
				this.alert = "Admin doesn't have right for any modules."
				return
			}
			this.existingUser = true
			this.modalPermissions = true
		},

	},

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
</style>

